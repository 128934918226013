<template>
  <div class="wrap-content">
    <!-- <div class="header">
      <div class="return">
        <div class="return-icon" @click="handleBack">
          <Icon type="ios-arrow-back" />
        </div>
        <span>选择要显示的列</span>
        <span> </span>
      </div>
      <div class="button">
        <CommonHeader />
      </div>
    </div>-->
    <div class="main">
      <div class="main-left">
        <div class="title">可选列(54)</div>
        <div class="columns-item">
          <div class="group">
            <Checkbox v-model="leftObj.basic.all" @click="checkAll('basic', leftObj.basic.all)">基本信息</Checkbox>
          </div>
          <div class="groups">
            <Checkbox :label="item.key" v-for="item in leftObj.basic.data" :key="item.key" v-model="item.isChecked"
              @on-change="changeCheck('basic', item)">{{ item.label }}</Checkbox>
          </div>
        </div>

        <div class="columns-item">
          <div class="group">
            <Checkbox v-model="leftObj.part.all" @click="checkAll('part')">数据载体情况</Checkbox>
          </div>
          <div class="groups">
            <Checkbox :label="item.key" v-for="item in leftObj.part.data" :key="item.key" v-model="item.isChecked"
              @on-change="changeCheck('part', item)">{{ item.label }}</Checkbox>
          </div>
        </div>
        <div class="columns-item">
          <div class="group">
            <Checkbox v-model="leftObj.dealWith.all" @click="checkAll('dealWith')">数据共享及出境情况</Checkbox>
          </div>
          <div class="groups">
            <Checkbox :label="item.key" v-for="item in leftObj.dealWith.data" :key="item.key" v-model="item.isChecked"
              @on-change="changeCheck('dealWith', item)">{{ item.label }}</Checkbox>
          </div>
        </div>
        <div class="columns-item">
          <div class="group">
            <Checkbox v-model="leftObj.safe.all" @click="checkAll('safe')">数据安全情况</Checkbox>
          </div>
          <div class="groups">
            <Checkbox :label="item.key" v-for="item in leftObj.safe.data" :key="item.key" v-model="item.isChecked"
              @on-change="changeCheck('safe', item)">{{ item.label }}</Checkbox>
          </div>
        </div>
        <div class="columns-item">
          <div class="group">
            <Checkbox v-model="leftObj.duty.all" @click="checkAll('duty')">责任主体情况</Checkbox>
          </div>
          <div class="groups">
            <Checkbox :label="item.key" v-for="item in leftObj.duty.data" :key="item.key" v-model="item.isChecked"
              @on-change="changeCheck('duty', item)">{{ item.label }}</Checkbox>
          </div>
        </div>
        <div class="columns-item">
          <div class="group">
            <Checkbox v-model="leftObj.audit.all" @click="checkAll('audit')">数据目录审核情况</Checkbox>
          </div>
          <div class="groups">
            <Checkbox :label="item.key" v-for="item in leftObj.audit.data" :key="item.key" v-model="item.isChecked"
              @on-change="changeCheck('safe', item)">{{ item.label }}</Checkbox>
          </div>
        </div>
        <div class="columns-item">
          <div class="group">
            <Checkbox v-model="leftObj.remark.all" @click="checkAll('remark')">备注补充</Checkbox>
          </div>
          <div class="groups">
            <Checkbox :label="item.key" v-for="item in leftObj.remark.data" :key="item.key" v-model="item.isChecked"
              @on-change="changeCheck('remark', item)">{{ item.label }}</Checkbox>
          </div>
        </div>
      </div>
      <div class="main-right">
        <div class="title">已选列({{ checkedArr.length }})</div>
        <ul>
          <li v-for="(item, index) in checkedArr" :key="index">
            <label for v-if="item.isChecked">{{ item.label }}</label>
            <div class="action" v-if="item.isChecked">
              <Icon type="md-arrow-dropup-circle" @click="Ascending(item, index)" />
              <Icon type="md-arrow-dropdown-circle" @click="Descending(item, index)" />
              <Icon type="md-remove-circle" @click="removeChecked(item, index)" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="action-box">
      <div class="btn btn-simple margin-right-10" @click="reset">恢复默认</div>
      <div class="btn btn-default" @click="submit" v-if="!isEdit">确认</div>
      <div class="btn btn-default" @click="updateData" v-if="isEdit">确认</div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, inject, onBeforeUnmount, nextTick } from "vue";
import { Message } from "view-ui-plus";
import CommonHeader from "@/components/commonHeader.vue";
import bus from "@/utils/bus";
import qs from "qs";
import router from "@/router";

let axios = inject("axios");
let checkedArr = ref([]);
let leftObj = ref({
  basic: {
    all: false,
    data: [
      {
        label: "数据名称",
        key: "dataName",
        isChecked: false,
      },

      {
        label: "数据分类",
        key: "type",
        isChecked: false,
      },
      {
        label: "数据级别",
        key: "level",
        isChecked: false,
      },
      {
        label: "数据描述",
        key: "description",
        isChecked: false,
      },
      {
        label: "分级依据",
        key: "gradeBasic",
        isChecked: false,
      },
      {
        label: "所在数据中心",
        key: "dataCenter",
        isChecked: false,
      },
      {
        label: "数据总量",
        key: "dataSize",
        isChecked: false,
      },
      {
        label: "数据记录数",
        key: "dataCount",
        isChecked: false,
      },
      {
        label: "数据主要来源",
        key: "source",
        isChecked: false,
      },
      {
        label: "数据精度",
        key: "precision",
        isChecked: false,
      },
      {
        label: "覆盖类型",
        key: "cover",
        isChecked: false,
      },
      {
        label: "覆盖具体情况",
        key: "coverDetail",
        isChecked: false,
      },
      {
        label: "覆盖占比",
        key: "ratio",
        isChecked: false,
      },
      {
        label: "覆盖占比测算依据",
        key: "ratioCalculationBasis",
        isChecked: false,
      },
    ],
  },
  part: {
    all: false,
    data: [
      {
        label: "数据载体",
        key: "payload",
        isChecked: false,
      },
      {
        label: "信息系统名称",
        key: "orgCode",
        isChecked: false,
      },
      {
        label: "服务网站域名",
        key: "domainName",
        isChecked: false,
      },
      {
        label: "服务网站IP地址",
        key: "webIpAddress",
        isChecked: false,
      },
      {
        label: "服务网站IP端口",
        key: "webIpPort",
        isChecked: false,
      },
      {
        label: "主机IP地址",
        key: "hostIpAddress",
        isChecked: false,
      },
      {
        label: "主机端口",
        key: "hostIpPort",
        isChecked: false,
      },
      {
        label: "是否进行ICP备案",
        key: "icpFilings",
        isChecked: false,
      },
      {
        label: "网络安全等级保护情况",
        key: "protection",
        isChecked: false,
      },
      {
        label: "是否为关键信息基础设施",
        key: "infrastructure",
        isChecked: false,
      },
    ],
  },
  dealWith: {
    all: false,
    data: [
      {
        label: "数据处理目的",
        key: "purpose",
        isChecked: false,
      },
      {
        label: "是否涉及跨主体流动",
        key: "flows",
        isChecked: false,
      },
      {
        label: "共享方式",
        key: "sharingMode",
        // key: "共享范围",
        isChecked: false,
      },
      {
        label: "共享范围",
        key: "shareRange",
        isChecked: false,
      },
      {
        label: "是否有涉外数据",
        key: "foreign",
        isChecked: false,
      },
      {
        label: "涉外数据来源情况",
        key: "foreignDataSource",
        isChecked: false,
      },
      {
        label: "是否出境",
        key: "abroad",
        isChecked: false,
      },
      {
        label: "出境方式",
        key: "exitMode",
        isChecked: false,
      },
      {
        label: "境外接收方",
        key: "overseasReceiver",
        isChecked: false,
      },
      {
        label: "是否完成数据出境安全评估",
        key: "safetyAssessment",
        isChecked: false,
      },
      {
        label: "数据出境安全评估结果",
        key: "assessmentResult",
        isChecked: false,
      },
    ],
  },
  safe: {
    all: false,
    data: [
      {
        label: "姓名",
        key: "chargeName",
        isChecked: false,
      },
      {
        label: "职务",
        key: "chargeTitle",
        isChecked: false,
      },
      {
        label: "联系方式",
        key: "chargeContact",
        isChecked: false,
      },
      {
        label: "是否进行数据安全风险评估",
        key: "assessment",
        isChecked: false,
      },
      {
        label: "评估机构",
        key: "evaluationOrg",
        isChecked: false,
      },
      {
        label: "评估依据规范",
        key: "specification",
        isChecked: false,
      },
      {
        label: "评估时间",
        key: "evaluationDate",
        isChecked: false,
      },
      {
        label: "评估结论",
        key: "conclusion",
        isChecked: false,
      },
    ],
  },
  duty: {
    all: false,
    data: [
      {
        label: "数据处理者名称",
        key: "processor",
        isChecked: false,
      },
      {
        label: "机构代码",
        key: "orgCode",
        isChecked: false,
      },
      {
        label: "省份",
        key: "province",
        isChecked: false,
      },
      {
        label: "市",
        key: "city",
        isChecked: false,
      },

    ],
  },
  audit: {
    all: false,
    data: [
      {
        label: "是否经过专家评审",
        key: "reviewed",
        isChecked: false,
      },
      {
        label: "是否经过学科领域专家评审",
        key: "fieldReviewed",
        isChecked: false,
      },
      {
        label: "是否经过数据管理专家评审",
        key: "managerReviewed",
        isChecked: false,
      },
      {
        label: "是否经过信息化管理专家评审",
        key: "informationReviewed",
        isChecked: false,
      },
      {
        label: "是否经过法定代表人审定",
        key: "legalReviewed",
        isChecked: false,
      },
    ],
  },
  remark: {
    all: false,
    data: [
      {
        label: "复核结果",
        key: "state",
        isChecked: false,
      },
      {
        label: "所属机构",
        key: "submitInst",
        isChecked: false,
      },
      {
        label: "填报人",
        key: "submitter",
        isChecked: false,
      },
      {
        label: "复核人",
        key: "reviewer",
        isChecked: false,
      },
      {
        label: "提交日期",
        key: "dataTime",
        isChecked: false,
      },
      {
        label: "备注",
        key: "remark",
        isChecked: false,
      },
    ],
  },
});

//必须分开监听，放在一起监听导致超过递归调用深度，不更新页面
watch(
  () => leftObj.value.basic.all,
  (val, oldVal) => {
    if (val) {
      leftObj.value.basic.data = leftObj.value.basic.data.map((item) => {
        return {
          ...item,
          isChecked: true,
        };
      });
      leftObj.value.basic.data.forEach((item) => {
        if (item.isChecked) {
          handleAdd("basic", item);
        }
      });
    }
  },
  { deep: true }
);
watch(
  () => leftObj.value.safe.all,
  (val, oldVal) => {
    if (val) {
      leftObj.value.safe.data = leftObj.value.safe.data.map((item) => {
        return {
          ...item,
          isChecked: true,
        };
      });
      leftObj.value.safe.data.forEach((item) => {
        if (item.isChecked) {
          handleAdd("safe", item);
        }
      });
    }
  },
  { deep: true }
);
watch(
  () => leftObj.value.part.all,
  (val, oldVal) => {
    if (val) {
      leftObj.value.part.data = leftObj.value.part.data.map((item) => {
        return {
          ...item,
          isChecked: true,
        };
      });
      leftObj.value.part.data.forEach((item) => {
        if (item.isChecked) {
          handleAdd("part", item);
        }
      });
    }
  },
  { deep: true }
);
watch(
  () => leftObj.value.dealWith.all,
  (val, oldVal) => {
    if (val) {
      leftObj.value.dealWith.data = leftObj.value.dealWith.data.map((item) => {
        return {
          ...item,
          isChecked: true,
        };
      });
      leftObj.value.dealWith.data.forEach((item) => {
        if (item.isChecked) {
          handleAdd("dealWith", item);
        }
      });
    }
  },
  { deep: true }
);
watch(
  () => leftObj.value.duty.all,
  (val, oldVal) => {
    if (val) {
      leftObj.value.duty.data = leftObj.value.duty.data.map((item) => {
        return {
          ...item,
          isChecked: true,
        };
      });
      leftObj.value.duty.data.forEach((item) => {
        if (item.isChecked) {
          handleAdd("duty", item);
        }
      });
    }
  },
  { deep: true }
);
watch(
  () => leftObj.value.audit.all,
  (val, oldVal) => {
    if (val) {
      leftObj.value.audit.data = leftObj.value.audit.data.map((item) => {
        return {
          ...item,
          isChecked: true,
        };
      });
      leftObj.value.audit.data.forEach((item) => {
        if (item.isChecked) {
          handleAdd("audit", item);
        }
      });
    }
  },
  { deep: true }
);
watch(
  () => leftObj.value.remark.all,
  (val, oldVal) => {
    if (val) {
      leftObj.value.remark.data = leftObj.value.remark.data.map((item) => {
        return {
          ...item,
          isChecked: true,
        };
      });
      leftObj.value.remark.data.forEach((item) => {
        if (item.isChecked) {
          handleAdd("remark", item);
        }
      });
    }
  },
  { deep: true }
);
//选中或者取消
const changeCheck = (name, item) => {
  //控制左侧对象的选中和取消
  let isAll = true;
  leftObj.value[name].data.forEach((item) => {
    if (!item.isChecked) {
      isAll = false;
    }
  });
  leftObj.value[name].all = isAll;
  if (item.isChecked) {
    handleAdd(name, item);
  } else {
    handleRemove(item);
  }
};

//每个小模块前的全选
const checkAll = (name, val) => {
  let result = leftObj.value[name].all;

  if (!result) {
    leftObj.value[name].data = leftObj.value[name].data.map((item) => {
      return {
        ...item,
        isChecked: true,
      };
    });
    result = true;
  } else {
    leftObj.value[name].data = leftObj.value[name].data.map((item) => {
      return {
        ...item,
        isChecked: false,
      };
    });
    result = false;
    leftObj.value[name].data.forEach((item) => {
      item.name = name;
      handleRemove(item);
    });
  }
};

//右侧删除
const removeChecked = (item, index) => {
  //改变左侧的选中状态
  let obj = leftObj.value[item.name];
  obj.data.forEach((each) => {
    if (each.key == item.key) {
      each.isChecked = false;
    }
  });
  //删除右侧的选中条件
  checkedArr.value.splice(index, 1);
  changeCheck(item.name, item);
};
//往右侧添加
const handleAdd = (name, item) => {
  checkedArr.value.push({
    ...item,
    name,
  });
  checkedArr.value = filterData(checkedArr.value);
};
//数组对象去重
const filterData = (arr) => {
  let newArr = [];
  let obj = {};
  for (var i = 0; i < arr.length; i++) {
    if (!obj[arr[i].key]) {
      newArr.push(arr[i]);
      obj[arr[i].key] = true;
    }
  }
  return newArr;
};
//通知右侧删除
const handleRemove = (item) => {
  checkedArr.value = checkedArr.value.filter((each) => {
    return each.key != item.key;
  });
};
//升序
const Ascending = (item, index) => {
  if (0 != index) {
    checkedArr.value.splice(
      index - 1,
      1,
      ...checkedArr.value.splice(index, 1, checkedArr.value[index - 1])
    );
  }
};
//降序
const Descending = (item, index) => {
  if (checkedArr.value.length - 1 != index) {
    checkedArr.value.splice(
      index + 1,
      1,
      ...checkedArr.value.splice(index, 1, checkedArr.value[index + 1])
    );
    console.log(checkedArr.value);
  }
};
//恢复默认,将渲染默认模板
const reset = () => {
  let val = leftObj.value;
  console.log(val)
  for (let key in val) {
    val[key].data = val[key].data.map((item) => {
      return {
        ...item,
        isChecked: false,
      };
    });
    val[key].all = false;
  }
  checkedArr.value = [];
  nextTick(() => {
    searchDefaultColumns();
  })

};

//查询用户默认列
const searchDefaultColumns = () => {
  let url = `/general/display/defaultLine`;
  axios.get(url).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      let data = res.data.data;
      // editData.value = data;  //只使用默认的列配置，不用查询的默认的id
      if (data != null && data.fields) {
        checkedArr.value = data.fields;
        isEdit.value = true;
        reduction();
      } else {
        isEdit.value = false;
      }
    }
  });
}
const submit = () => {
  let newArr = checkedArr.value;
  let url = `/general/display/create`;
  let params = {
    form: "important",
    fields: newArr,
    type: parameter.value,
    userAccount: account.value,
  };
  axios
    .post(url, params, {
      headers: {
        "content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res);
      if (res.data.code == 200) {
        Message.success({
          background: true,
          content: "设置展示列成功",
        });
        bus.emit("closeModal");
        // router.push("")
      } else {
        Message.error({
          background: true,
          content: res.data.message,
        });
      }
    });
};

let editData = ref({});
let isEdit = ref(false);
//更新
const updateData = () => {
  let newArr = checkedArr.value;
  let url = `/general/display/edit`;
  let params = {
    form: "important",
    fields: newArr,
    type: parameter.value,
    userAccount: account.value,
    id: editData.value.id,
  };
  axios
    .post(url, params, {
      headers: {
        "content-Type": "application/json",
      },
    })
    .then((res) => {
      // console.log(res);
      if (res.data.code == 200) {
        Message.success({
          background: true,
          content: "设置展示列成功",
        });
        bus.emit("closeModal");
        //通知组件进行刷新获取最新的columns和tableData
        bus.emit('noticeReload');
      } else {
        Message.error({
          background: true,
          content: res.data.message,
        });
      }
    });
};

//获取用户信息
const getUserInfo = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    account.value = user.userInfo.cstnetId;
  }
};
//查询用户已有的自定义列
const getColumns = () => {
  let url = `/general/display/type`;
  let params = {
    form: "important",
    type: parameter.value,
  };
  axios.get(url, { params }).then((res) => {
    // console.log(res);
    if (res.data.code == 200) {
      let data = res.data.data;
      editData.value = data;
      if (data != null && data.fields) {
        checkedArr.value = data.fields;
        isEdit.value = true;
        reduction();
      } else {
        isEdit.value = false;
      }
    }
  });
};
let account = ref("");
let parameter = ref("");

//将用户原有设置的列进行还原选中
const reduction = () => {
  let data = checkedArr.value;
  let obj = leftObj.value;
  data.forEach((item) => {
    obj[item.name].data.forEach((each, i) => {
      if (item.key == each.key) {
        obj[item.name].data[i].isChecked = true;
      }
    });
  });
};
onMounted(() => {
  getUserInfo();

  bus.on("source", (data) => {
    if (data == "record") {
      parameter.value = "record";
    } else if (data == "audit") {
      parameter.value = "audit";
    }
    getColumns();
  });
});
onBeforeUnmount(() => {
  bus.all.delete('source');
})
</script>

<style lang="scss" scoped>
.wrap-content {
  // padding: 0 40px;
  // background: #fff;
  // min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

  .return {
    display: flex;
    align-items: center;

    .return-icon {
      width: 24px;
      height: 24px;
      border: solid 2px #111111;
      border-radius: 6px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;

      i {
        font-size: 18px;
        color: #000;
      }
    }

    span {
      font-family: PingFangSC-Medium;
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      color: #111111;
    }

    span:last-child {
      color: #cccccc;
    }
  }
}

.button {
  display: flex;
  align-items: center;

  > div {
    margin-left: 20px;
  }

  .btn-add {
    color: #fff;
    cursor: pointer;

    i {
      padding-right: 5px;
    }
  }

  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}

.main {
  display: flex;

  .main-left {
    width: 70%;
    border: solid 1px #eeeeee;
    border-radius: 6px;
    .title {
      height: 50px;
      background-image: linear-gradient(#f6f6f6, #f6f6f6),
        linear-gradient(#2c399d, #2c399d);
      background-blend-mode: normal, normal;
      border-bottom: solid 1px #eeeeee;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 46px;
      letter-spacing: 0px;
      color: #111111;
      padding-left: 20px;
    }

    .columns-item {
      // padding: 10px 20px;
      display: flex;
      border-bottom: solid 1px #eeeeee;

      .group {
        width: 200px;
        display: flex;
        align-items: center;
        border-right: solid 1px #eeeeee;

        > label {
          padding-left: 20px;
        }

        :deep(.ivu-checkbox-wrapper) {
          .ivu-checkbox-label-text {
            padding-left: 10px;
            font-size: 16px;
            color: #111111;
          }
        }
        :deep(.ivu-checkbox-wrapper-checked) {
          .ivu-checkbox-label-text {
            color: #3d55a8;
          }
        }
      }

      .groups {
        width: calc(100% - 200px);
        padding-left: 20px;

        :deep(.ivu-checkbox-wrapper) {
          margin: 15px 0;
          width: 25%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .ivu-checkbox-label-text {
            padding-left: 10px;
            font-size: 14px;
            color: #555555;
          }
        }
        :deep(.ivu-checkbox-wrapper-checked) {
          .ivu-checkbox-label-text {
            color: #3d55a8;
          }
        }
      }
    }
  }

  .main-right {
    flex-grow: 1;
    margin-left: 30px;
    border: solid 1px#eff3fe;
    border-radius: 6px;
    .title {
      height: 50px;
      background-image: linear-gradient(#eff3fe, #eff3fe),
        linear-gradient(#2c399d, #2c399d);
      background-blend-mode: normal, normal;
      line-height: 46px;
      letter-spacing: 0px;
      color: #3d55a8;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      padding-left: 20px;
    }

    ul {
      padding: 20px;
      max-height: 800px;
      overflow-y: auto;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 46px;

        // margin-bottom: 30px;
        label {
          color: #3d55a8;
        }

        .action {
          color: #cccccc;

          i:not(:last-child):hover {
            color: #3d55a8;
            cursor: pointer;
          }

          i:last-child:hover {
            color: #f30707;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.action-box {
  margin: 40px 0 30px 0;
  display: flex;
  justify-content: center;
  align-content: center;
  > div {
    width: 100px;
  }
}
</style>